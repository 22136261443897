import styled, { css } from 'react-emotion'
import { FONT_SMALL } from '../constants'

const Col = styled.div`
  box-sizing: border-box;
  white-space: normal;
  padding: 0 10px;

  & p {
    padding-right: 20px;
  }

  @media only screen and (max-width: 1023px) {
    margin-bottom: 20px;
    ${props => (props.mobile ? '' : 'width: 100%;')};
  }
`

export const Two = styled(Col)`
  width: 50%;
`

export const Three = styled(Col)`
  width: 75%;
`

export const Four = styled(Col)`
  width: 100%;
`

export const One = styled(Col)`
  width: 25%;

  & p {
    padding-right: 10px;
  }

  @media only screen and (max-width: 1023px) {
    & + ${Three} {
      order: -1;
      margin-bottom: 20px;
    }
  }
`
export const TwoThirds = styled(Col)`
  width: 66%;
`

export const OneThird = styled(Col)`
  width: 33%;
`

const scrollable = css`
  font: ${FONT_SMALL};
  overflow-x: auto;
  white-space: nowrap;

  @media only screen and (max-width: 1023px) {
    margin-left: -15px;
    padding-left: 15px;
    width: calc(100% + 30px);
    flex-wrap: nowrap;
    white-space: normal;

    & > ${One} {
      width: 50%;
      min-width: 50%;
    }
  }
`

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 40px;
  margin-left: -10px;
  width: calc(100% + 20px);

  ${props => props.scrollable && scrollable};
`

export const RowGroup = styled.div`
  margin-bottom: 40px;

  & > ${Row} {
    margin-bottom: 20px;
  }
`
