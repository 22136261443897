import React from 'react'
import styled from 'react-emotion'
import Link from './Link'
import {
  BRAND_COLOR,
  BRAND_COLOR_UNDERLINE,
  COLOR_UNDERLINE,
  CONTRAST_COLOR,
  FONT_NORMAL,
  FONT_SMALL,
} from '../constants'
import OfficeContacts from './OfficeContacts'

const activeLinkStyle = {
  borderRadius: 4,
  backgroundColor: BRAND_COLOR,
  borderBottom: 'none',
  padding: '2px 8px',
  color: CONTRAST_COLOR,
}

const isArticlesActive = (match, location) => {
  return (
    location.pathname.startsWith('/articles') ||
    location.pathname.startsWith('/blog')
  )
}

export default () => (
  <Header>
    <LogoContainer>
      <Link to="/" noUnderline>
        <Logo src="/assets/images/500-logo.png" alt=""/>
      </Link>
    </LogoContainer>

    <FlexBreak/>

    <MainNavigation>
      <MenuBlock>
        Services
        <ul className="horizontal">
          <li>
            <Link to="/projects" activeStyle={activeLinkStyle}>
              Projects
            </Link>
          </li>
          <li>
            <Link to="/training" activeStyle={activeLinkStyle}>
              Training
            </Link>
          </li>
          <li>
            <Link to="/consulting" activeStyle={activeLinkStyle}>
              Consulting
            </Link>
          </li>
        </ul>
      </MenuBlock>

      <MenuBlock>
        Work
        <ul className="horizontal">
          <li>
            <Link to="/clients" activeStyle={activeLinkStyle}>
              Clients
            </Link>
          </li>
          <li>
            <Link to="/testimonials" activeStyle={activeLinkStyle}>
              Reviews
            </Link>
          </li>
          <li>
            <Link to="/faq" activeStyle={activeLinkStyle}>
              FAQ
            </Link>
          </li>
        </ul>
      </MenuBlock>

      <MenuBlock>
        Publications
        <ul className="horizontal">
          <li>
            <Link to="/talks" activeStyle={activeLinkStyle}>
              Talks
            </Link>
          </li>
          <li>
            <Link
              to="/articles"
              activeStyle={activeLinkStyle}
              isActive={isArticlesActive}>
              Articles
            </Link>
          </li>
        </ul>
      </MenuBlock>

      <MenuBlock>
        Company
        <ul className="horizontal">
          <li>
            <Link to="/jobs" activeStyle={activeLinkStyle}>
              Jobs
            </Link>
          </li>
          <li>
            <Link to="/contact" activeStyle={activeLinkStyle}>
              Contact
            </Link>
          </li>
        </ul>
      </MenuBlock>
    </MainNavigation>

    <FlexBreak/>

    <OfficeContacts
      location={{
        address: 'HaYetsira 19, Ramat Gan',
        city: 'Tel Aviv',
        country: 'Israel',
        email: 'info@500tech.com',
      }}
    />
  </Header>
)

const Header = styled.header`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  font: ${FONT_SMALL};
  margin-bottom: 60px;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    justify-content: normal;
  }
`

const Logo = styled.img`
  height: 86px;
  margin-right: 30px;
  display: inline-block;
  vertical-align: top;

  @media only screen and (max-width: 1023px) {
    margin-bottom: 20px;
  }
`

const MainNavigation = styled.nav`
  font: ${FONT_NORMAL};
  display: flex;
  flex: 1;
  overflow: auto;
  @media only screen and (max-width: 1023px) {
    order: 4;
    flex: initial;
    margin-top: 20px;
  }

  & a,
  & a:visited,
  & a:active {
    color: ${CONTRAST_COLOR};
    text-decoration: none;
    border-bottom: ${COLOR_UNDERLINE};
  }

  & a:hover {
    color: ${BRAND_COLOR};
    border-bottom: ${BRAND_COLOR_UNDERLINE};
  }

  & ul > li {
    font-size: 1rem;
    margin-right: 10px;
  }
`

const MenuBlock = styled.div`
  font-size: 1rem;
  margin-left: 20px;

  @media only screen and (max-width: 1023px) {
    margin-left: 0;
    margin-right: 20px;
  }
`

const FlexBreak = styled.div`
  @media only screen and (min-width: 1023px) {
    display: none;
  }

  @media only screen and (max-width: 1023px) {
    display: block;
  }
`

const LogoContainer = styled.div`
  @media only screen and (max-width: 1023px) {
    flex: 1 0 auto;
  }
`
