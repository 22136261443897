export const COLOR = '#b1a7a7'
export const BRAND_COLOR = '#fa1851'
export const BRAND_HOVER_COLOR = '#c90034'
export const PANEL_COLOR = '#333'
export const BG_COLOR = '#191919'
export const CONTRAST_COLOR = '#fff'
export const COLOR_UNDERLINE = '1px solid rgba(255, 255, 255, 0.25)'
export const BRAND_COLOR_UNDERLINE = '1px solid rgba(240, 24, 81, 0.25)'
export const BRAND_HOVER_COLOR_UNDERLINE = '1px solid rgba(201, 0, 52, 0.4)'
export const FONT_FAMILY = "'Gotham A', 'Gotham B', 'Gotham', sans-serif;"
export const FONT_SMALL = `300 14px/20px ${FONT_FAMILY}`
export const FONT_NORMAL = `300 20px/25px ${FONT_FAMILY}`
export const FONT_BIG = `300 24px/1.4 ${FONT_FAMILY}`
