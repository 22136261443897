import React from 'react'
import styled from 'react-emotion'
import OfficeContacts from './OfficeContacts'
import { OneThird, Row, Two } from './Grid'
import { FONT_SMALL } from '../constants'
import Link from './Link'

export default ({ offices }) => (
  <Footer>
    <Row>
      <Two>
        <Social>
          <ul className="horizontal">
            <li>
              <Link to="https://www.linkedin.com/company/500Tech/" noUnderline>
                LinkedIn&nbsp;&nbsp;&nbsp;&nbsp;
              </Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/500Tech.Ltd/" noUnderline>
                Facebook&nbsp;&nbsp;&nbsp;&nbsp;
              </Link>
            </li>
            <li>
              <Link to="https://github.com/500tech" noUnderline>
                GitHub&nbsp;&nbsp;&nbsp;&nbsp;
              </Link>
            </li>
            <li>
              <Link
                to="https://www.youtube.com/500TechIL/playlists"
                noUnderline>
                YouTube&nbsp;&nbsp;&nbsp;&nbsp;
              </Link>
            </li>
          </ul>
        </Social>
        <br />
        <ul>
          <li>
            <Link to="/privacy" subtle onUnderline>
              Privacy policy
            </Link>
          </li>
        </ul>

        <Copyright>
          &copy; 500Tech. Building high-quality software since 2012.
        </Copyright>
      </Two>

      <Two>
        <Row>
          <OneThird />
          <OneThird />

          <OneThird>
            <OfficeContacts
              location={offices.find(({ city }) => city === 'Tel Aviv')}
            />
          </OneThird>
        </Row>
      </Two>
    </Row>
  </Footer>
)

const Footer = styled.div`
  font: ${FONT_SMALL};
  margin-top: 60px;

  @media only screen and (max-width: 1023px) {
    margin-top: 60px;
  }
`

const Social = styled.div`
  margin-top: 20px;
`

const Copyright = styled.div`
  margin-top: 60px;
  font: ${FONT_SMALL};
`
