import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled, { css } from 'react-emotion'

import {
  BRAND_COLOR,
  BRAND_COLOR_UNDERLINE,
  BRAND_HOVER_COLOR,
  BRAND_HOVER_COLOR_UNDERLINE,
  COLOR,
  COLOR_UNDERLINE,
  CONTRAST_COLOR,
} from '../constants'

export default ({ children, to, ...rest }) => {
  if (!to) {
    return (
      <StyledLink href="" {...rest}>
        {children}
      </StyledLink>
    )
  }

  const external = to.startsWith('http') || to.startsWith('mailto:') || to.startsWith('tel:');

  if (external) {
    return (
      <StyledLink href={to} target="_blank" {...rest}>
        {children}
      </StyledLink>
    )
  }

  return (
    <StyledGatsbyLink to={to} {...rest}>
      {children}
    </StyledGatsbyLink>
  )
}

const subtleLink = css`
  &,
  &:active,
  &:visited {
    color: ${COLOR};
    text-decoration: none;
    border-bottom: ${COLOR_UNDERLINE};
  }

  &:hover {
    color: ${BRAND_COLOR};
    border-bottom: ${BRAND_COLOR_UNDERLINE};
  }
`

const whiteLink = css`
  &,
  &:active,
  &:visited {
    color: ${CONTRAST_COLOR};
    text-decoration: none;
    border-bottom: ${COLOR_UNDERLINE};
  }

  &:hover {
    color: ${BRAND_COLOR};
    border-bottom: ${BRAND_COLOR_UNDERLINE};
  }
`

const noUnderlineLink = css`
  &,
  &:hover {
    border-bottom-width: 0;
  }
`

const innerHighlightLink = css`
  display: block;

  & + * a.inner-link,
  & + * > a {
    padding-top: 26px;
  }

  &:hover + * a.inner-link,
  &:hover + * > a {
    color: ${BRAND_COLOR};
    border-bottom: ${BRAND_COLOR_UNDERLINE};
  }
`

const StyledLink = styled.a`
  &,
  &:visited {
    color: ${BRAND_COLOR};
    text-decoration: none;
    border-bottom: ${BRAND_COLOR_UNDERLINE};
  }

  &:hover,
  &:active {
    cursor: pointer;
    color: ${BRAND_HOVER_COLOR};
    border-bottom: ${BRAND_HOVER_COLOR_UNDERLINE};
  }

  ${props => props.subtle && subtleLink};
  ${props => props.white && whiteLink};
  ${props => props.noUnderline && noUnderlineLink};
  ${props => props.innerHighlight && innerHighlightLink};
`

const StyledGatsbyLink = StyledLink.withComponent(GatsbyLink)
