import styled, { css } from 'react-emotion'
import { CONTRAST_COLOR, FONT_SMALL } from '../constants'

export const Section = styled.section`
  margin-bottom: 60px;

  @media only screen and (max-width: 1023px) {
    margin-bottom: 40px;
  }
`

const HeadingStyle = css`
  color: ${CONTRAST_COLOR};
  font-weight: 300;
  margin: 0;
`

export const H1 = styled.h1`
  ${HeadingStyle};

  font-size: 80px;
  line-height: 80px;
  letter-spacing: -0.02em;
  margin-bottom: 26px;
  max-width: 80%;

  @media only screen and (max-width: 1023px) {
    min-width: 100%;
    font-size: 48px;
    line-height: 1.2;
  }
`

export const H2 = styled.h2`
  ${HeadingStyle};

  font-weight: 600;
  max-width: 720px;
  margin: 0 0 0.5em 0;
  font-size: 1.3em;
  line-height: 1.3em;
`

export const H3 = styled.h3`
  ${HeadingStyle};

  font-weight: 600;
  font-size: 1em;
  margin: 0;
`

export const H4 = styled.h4`
  ${HeadingStyle};
`

export const ProjectName = styled(H1)`
  margin-bottom: 60px;

  @media only screen and (max-width: 1023px) {
    margin-bottom: 40px;
  }
`

export const SmallText = styled.div`
  font: ${FONT_SMALL};
`

// SEO Recommendations by IronPaper
// Remove if not working
export const SEOH1 = H1.withComponent('h2');
export const SEOH4 = styled.h1`
  font-size: 14px;
  font-weight: normal;
`;