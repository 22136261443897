import React from 'react'

const { Provider, Consumer: CTXConsumer } = React.createContext()

const Consumer = ({ children }) => (
  <CTXConsumer>
    {value => {
      const allImages = value.reduce(
        (result, { node }) => ({
          ...result,
          [node.relativePath]: node.childImageSharp,
        }),
        {}
      )

      return children(allImages)
    }}
  </CTXConsumer>
)

export default {
  Provider,
  Consumer,
}
