import React from 'react'

const { Provider, Consumer: CTXConsumer } = React.createContext()

const Consumer = ({ children }) => (
  <CTXConsumer>
    {allClients => children(allClients)}
  </CTXConsumer>
)

export default {
  Provider,
  Consumer,
}
