import React from 'react'

const { Provider, Consumer: CTXConsumer } = React.createContext()

const Consumer = ({ children }) => (
  <CTXConsumer>
    {allTalks => children(allTalks)}
  </CTXConsumer>
)

export default {
  Provider,
  Consumer,
}
