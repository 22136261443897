import React from 'react'
import styled from 'react-emotion'
import { FONT_SMALL } from '../constants'
import { H4 } from './Style'
import Link from './Link'

export default props => (
  <Contact>
    <ul>
      <li>
        <Link to={`mailto:${props.location.email}`} subtle noUnderline>
          {props.location.email}
        </Link>
      </li>

      <li>{props.location.address}</li>
    </ul>
  </Contact>
)

const Contact = styled.div`
  font: ${FONT_SMALL};

  &:not(:last-of-type) {
    margin-right: 30px;
  }
`
