import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import styled from 'react-emotion'
import { StaticQuery, graphql } from 'gatsby'

import './index.css'

import Header from '../components/header'
import Footer from '../components/footer'
import ProjectsContext from '../components/ProjectsContext'
import ImagesContext from '../components/ImagesContext'
import TalksContext from '../components/TalksContext'
import ClientsContext from '../components/ClientsContext'
import { BG_COLOR, COLOR, FONT_NORMAL } from '../constants'

export default ({ children }) => (
  <Fragment>
    <Helmet>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale = 1.0, maximum-scale=1.0, user-scalable=no"/>

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@_500Tech" />

      <link rel="apple-touch-icon" sizes="57x57" href="/favicons/apple-icon-57x57.png"/>
      <link rel="apple-touch-icon" sizes="60x60" href="/favicons/apple-icon-60x60.png"/>
      <link rel="apple-touch-icon" sizes="72x72" href="/favicons/apple-icon-72x72.png"/>
      <link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-icon-76x76.png"/>
      <link rel="apple-touch-icon" sizes="114x114" href="/favicons/apple-icon-114x114.png"/>
      <link rel="apple-touch-icon" sizes="120x120" href="/favicons/apple-icon-120x120.png"/>
      <link rel="apple-touch-icon" sizes="144x144" href="/favicons/apple-icon-144x144.png"/>
      <link rel="apple-touch-icon" sizes="152x152" href="/favicons/apple-icon-152x152.png"/>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-icon-180x180.png"/>
      <link rel="icon" type="image/png" sizes="192x192" href="/favicons/android-icon-192x192.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>

      <link rel="manifest" href="/manifest.json" />

      <meta name="msapplication-TileColor" content="#191919" />
      <meta name="msapplication-TileImage" content="/favicons/ms-icon-144x144.png"/>
      <meta name="theme-color" content="#191919" />

      <link href="https://cloud.typography.com/7398156/6726792/css/fonts.css" rel="stylesheet" type="text/css"/>

      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "WebSite",
          "name": "500Tech",
          "url": "https://500tech.com"
        }
      `}</script>

      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "url": "https://500tech.com",
          "sameAs": [
            "https://www.facebook.com/500Tech.Ltd",
            "https://twitter.com/_500Tech",
            "https://www.linkedin.com/company/500Tech",
            "https://github.com/500tech"
          ],
          "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+972-33760508",
            "contactType": "sales",
            "areaServed": ["IL"],
            "availableLanguage": ["Hebrew","English"]
          },{
            "@type": "ContactPoint",
            "telephone": "+1-347-935-9233",
            "contactType": "sales",
            "areaServed": ["US"],
            "availableLanguage": ["English","Hebrew"]
          }]
        }
      `}</script>
    </Helmet>

    <StaticQuery
      query={query}
      render={data => (
        <SiteWrapper>
          <ProjectsContext.Provider
            value={data.projectsFile.frontmatter.projects}>
            <ImagesContext.Provider value={data.allFile.edges}>
              <TalksContext.Provider value={data.talksFile.frontmatter.talks}>
                <ClientsContext.Provider
                  value={data.clientsFile.frontmatter.clients}>
                  <Header />
                  {children}
                  <Footer offices={data.companyFile.frontmatter.offices} />
                </ClientsContext.Provider>
              </TalksContext.Provider>
            </ImagesContext.Provider>
          </ProjectsContext.Provider>
        </SiteWrapper>
      )}
    />
  </Fragment>
)

const SiteWrapper = styled.div`
  background-color: ${BG_COLOR};
  color: ${COLOR};
  font: ${FONT_NORMAL};
  padding: 15px 30px;
  max-width: 1280px;
  margin: 0 auto;

  @media only screen and (max-width: 1023px) {
    padding: 15px;
  }
`

const query = graphql`
  {
    projectsFile: markdownRemark(fileAbsolutePath: { regex: "/projects.md/" }) {
      frontmatter {
        projects {
          projectName
          releaseDate
          projectLength
          technologies
          platforms
          features
          team
          clientName
          clientLogo
        }
      }
    }
    talksFile: markdownRemark(fileAbsolutePath: { regex: "/talks.md/" }) {
      frontmatter {
        talks {
          date
          event
          country
          city
          speaker
          title
          language
          url
          thumbnail
        }
      }
    }
    clientsFile: markdownRemark(fileAbsolutePath: { regex: "/clients.md/" }) {
      frontmatter {
        clients {
          name
          website
          size
          industry
          logo
          description
        }
      }
    }
    companyFile: markdownRemark(fileAbsolutePath: { regex: "/company.md/" }) {
      frontmatter {
        offices {
          city
          country
          email
          address
        }
      }
    }
    allFile(filter: { extension: { ne: "svg" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            size: original {
              width
              height
            }
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
