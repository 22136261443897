import React from 'react'

const { Provider, Consumer: CTXConsumer } = React.createContext()

const Consumer = ({ children, clientName }) => {
  if (!clientName) {
    return (
      <CTXConsumer>{value => children({ allProjects: value })}</CTXConsumer>
    )
  }

  return (
    <CTXConsumer>
      {value =>
        children({
          allProjects: value,
          currentProject: value.find(p => p.clientName === clientName),
        })
      }
    </CTXConsumer>
  )
}

export default {
  Provider,
  Consumer,
}
